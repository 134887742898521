import template from './tickets.html';
import seatsTemplate from './seats/seats.html';
import Papa from '../../../public/custom/js/papaparse.min.js';
import './tickets.less';

export default angular.module('eventix.shop.tickets', [])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.tickets', {
            url: '/tickets?eventId&postal',
            views: {
                'shop': {
                    controller: 'TicketsController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/ {}
        });
    })
    .controller('TicketsController', function(UIMessages, shop, order, collapses, $state, $scope, $q, $http, $translate, Locales, Currencies, Ticket, $interval, $sce, $analytics, FB, $timeout, store, $filter, $location, SeatsIO, ShopAnalytics) {
        var vm = this;
        vm.seatSelection = {
            templateUrl: seatsTemplate,
            open: false
        };
        vm.order = order;
        vm.messages = UIMessages;
        vm.locales = Locales;
        vm.currencies = Currencies;
        vm.shop = shop;
        vm.tickets = order.tickets;
        vm.events = order.events;
        vm.products = order.products;
        vm.collapses = collapses;
        vm.eventFilter = $state.params.eventId;
        vm.currentEvent;

        vm.mappingLoaded = false;
        vm.allPostalsLoaded = false;
        vm.$onInit = function () {
            vm.fetchShopUrl();
        };

        let queryParams = $location.search();
        if (queryParams.coupon) {
            vm.order.applyCoupon(queryParams.coupon);
        }

        /** Iterate over events/tickets and check availability */
        vm.availableTickets = order.recalculateAvailableTickets();
        var checkAvailable = $interval(() => vm.availableTickets = order.recalculateAvailableTickets(), 10000);
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' || (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto');
        $scope.$on('destroy', () => $interval.cancel(checkAvailable));

        let today = moment();
        let minStart = moment("2023-01-01");
        let minDate;
        if (today.isAfter(minStart)) {
            minDate = today;
        } else {
            minDate = minStart;
        }

        let currentDate;
        if (vm.eventFilter) {
            let currentEvent = vm.events[vm.eventFilter];
            currentDate = getDateFromCurrentEvent(currentEvent.name);
        } else {
            currentDate = minDate;
        }

        if (!vm.eventFilter) {
            updateStateToEvent();
        }

        vm.updateEvent = updateStateToEvent;

        function updateStateToEvent() {
            let CurrentSearch = moment(currentDate).locale('en').format('YYYY-MM-DD');
            _.each(vm.events, function(e) {
                let eventName = e.name;
                let eventDate = getDateFromCurrentEvent(eventName);
                if (eventDate == CurrentSearch) {
                    vm.noTickets = false;
                    $state.go('.', {eventId: e.guid, postal: vm.postcode});
                    return;
                }
                vm.noTickets = true;
            });
        }

        function getDateFromCurrentEvent(currentEventName) {
            let split = currentEventName.split("-");
            let dateSplits = split[1].split(" ");
            let currentEventDate = dateSplits[1] + "-" + dateSplits[2] + "-" + dateSplits[3] + " 2020";
            return moment(currentEventDate).format('YYYY-MM-DD');
        }

        // if(vm.fourStepWizard && !vm.eventFilter)
        //     $state.go('eventix.shop.events', { shopId: vm.shop.guid });

        if (vm.fourStepWizard && vm.eventFilter) {
            ShopAnalytics.impression(vm.tickets[vm.eventFilter]);
        } else if (!vm.fourStepWizard) {
            ShopAnalytics.impression();
        }

        vm.setTickets = function() {
            vm.prefix = '';
            if (vm.eventFilter) {
                let currentEvent = vm.currentEvent = vm.events[vm.eventFilter];
                // console.log(currentEvent.name);
                currentDate = getDateFromCurrentEvent(currentEvent.name);
                // console.log(currentDate);
                let split = currentEvent.name.split('-');
                vm.prefix = split[0].trim();
            }

            vm.currentTickets = {};
            vm.currentTicketGuids = [];

            vm.checkForEventId = true;
            vm.currentEvents = _.filter(_.map(vm.events, function(e) {
                // console.log(vm.events);
                let splits = e.name.split('-')[1];
                let daySplits = splits.split(' ');
                e.dayName = daySplits[1];
                e.dayNumber = daySplits[2];
                e.dayMonth = daySplits[3];
                if (e.name.startsWith(vm.prefix)) {
                    if(!vm.eventFilter && vm.checkForEventId){
                        $state.go('.', {eventId: e.guid, postal: vm.postcode});
                        vm.checkForEventId = false;
                    }
                    return e;
                }
                return null;
            }));

            vm.currentEventGuids = vm.currentEvents.map(e => e.guid);
            _.each(vm.tickets, function(tickets, eventId) {
                if (_.indexOf(vm.currentEventGuids, eventId) !== -1) {
                    if (!vm.eventFilter) {
                        vm.currentTickets[eventId] = tickets;
                        vm.currentTicketGuids = vm.currentTicketGuids.concat(tickets.map(t => t.guid));
                    } else if (vm.eventFilter === eventId) {
                        vm.currentTickets[eventId] = tickets;
                        vm.currentTicketGuids = vm.currentTicketGuids.concat(tickets.map(t => t.guid));
                    }
                }
            });
        };
        $scope.$watch('vm.eventFilter', vm.setTickets, true);

        vm.setCurrentEvent = function(eventId) {
            if(vm.order.firstReservation){
                // has revervations -> do not allow switching of dates -> request of Job due to their system
                UIMessages.push('Niet mogelijk om voor meerdere dagen tegelijk te bestellen');
                return;
            }

            $state.go('.', {eventId: eventId, postal: vm.postcode});
        };

        /**
         * Close seat selection dialog
         */
        vm.closeSeatSelection = function() {
            vm.seatSelection.open = false;
        };

        vm.openSeatSelection = function(ticket, eventId) {
            SeatsIO.open(ticket, eventId).then(() => vm.seatSelection.open = true);
        };

        /** Goto next page in shop if user has selected 1+ ticket */
        vm.next = function() {
            if(!vm.valid){
                UIMessages.push('Postcode niet geldig');
                return;
            }

            if (_.flatten(_.values(order.reservations)).length < 1) {
                UIMessages.push('common.shop.order.empty');
            } else {
                $state.go('eventix.shop.personalDetails',{ shopId: shop.guid , eventId: $state.params.eventId, postal: vm.postcode});
            }
        };

        vm.reserve = function(ticketGuid, eventId) {
            vm.order.reserveTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.addToCart(ticket);
        };

        vm.toId = function(string) {
            return string.replace(/\W/g, '');
        };

        /* *********************************************** *
         * ******************* GP STUFF ****************** *
         * *********************************************** */

        function debounce(fn, delay) {
            var timeoutPromise;

            return function() {
                if (angular.isObject(timeoutPromise) && timeoutPromise !== null) {
                    $timeout.cancel(timeoutPromise);
                }
                timeoutPromise = $timeout(fn, delay);
            };
        }

        function validatePostal(){
            let postal = vm.postcode
            let pattern = RegExp('[1-9][0-9]{3}[ ]?([A-RT-Za-rt-z][A-Za-z]|[sS][BCbcE-Re-rT-Zt-z])');
            if(pattern.test(postal)){
                return true;
            }
            return false;
        }

        $scope.$watch('vm.postcode', debounce(function(newVal, oldVal) {
            if(newVal !== oldVal) {
                submitPostal();
            }
        }, 500));

        vm.postcode = '';
        vm.postcodeObject = null;
        vm.opstapObjects = [];
        vm.postcodeTickets = {};
        vm.valid = false;

        vm.submitPostal = submitPostal;

        let postalFromGET = $location.search().postal;
        if(typeof postalFromGET !== 'undefined' && postalFromGET !== ''){
            vm.postcode = postalFromGET;
            submitPostal();
        }

        vm.shopCsvUrl = '';

        vm.fetchShopUrl = function() {
            Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vSmwehr2tuTrDjlBzFJBmOWw2pW4xAEKtpBHQfgk7GWa3dRnsXhzMFkyW59VJDkcrSFFKpR9xkWmWzL/pub?gid=572159927&single=true&output=csv', {
                download: true,
                header: true,
                skipEmptyLines: true,
                step: function (row) {
                    const shopMapping = row.data;

                    if ((row.errors && row.errors.length) || !shopMapping || !shopMapping.shop_id || !shopMapping.csv_url) {
                        console.error('Mapping data invalid, error found or does not contain "shop_id" and "csv_url" field', row.data);
                    } else if (shopMapping.shop_id == vm.shop.guid) {
                        vm.shopCsvUrl = shopMapping.csv_url
                    }
                },
                error: function (error) {
                    console.error('Failed to load mapping', error);

                    $scope.$apply();
                },
                complete: function () {
                    console.log("csv url: ", vm.shopCsvUrl)
                    vm.mappingLoaded = true;
                }
            });
        }

        vm.findClosestTickets = function() {
            if(vm.mappingLoaded == false || vm.allPostalsLoaded == false){
                UIMessages.push('Data not loaded yet, try again');
                return;
            }

            Papa.parse(vm.shopCsvUrl, {
                download: true,
                header: true,
                skipEmptyLines: true,
                step: function (row) {
                    const opstap = row.data;

                    if ((row.errors && row.errors.length) || !opstap || !opstap.lat || !opstap.lon) {
                        console.error('Opstap data invalid, error found or does not contain "lat" and "lon" field', row.data);
                    } else if (opstap.lat >= vm.minlat && opstap.lat <= vm.maxlat && opstap.lon >= vm.minlon && opstap.lon <= vm.maxlon) {
                        // If the opstaplocation is within the pre-filter square, calculate its exact distance to the visitor's location.
                        // Note however: when k deg lat = x km and k deg lon = y km -> x != y.
                        // This is because the exact length of a longitude degree depends on it's latitude.
                        // As long as the opstap locations are distributed fairly even, this should be acceptable-ish,
                        // as the closest will most likely still be in the top 3, just the order will be a bit off.
                        opstap.deltalat = Math.abs(opstap.lat - vm.postcodeObject.latitude);
                        opstap.deltalon = Math.abs(opstap.lon - vm.postcodeObject.longitude);

                        // No need for squareroot, as the relative difference is more important
                        opstap.distance = opstap.deltalon * opstap.deltalon + opstap.deltalat * opstap.deltalat;

                        opstap.raw_postcode = opstap['Vertrek: postcode'].replace(/[^a-zA-Z0-9]*/, '').toLowerCase();

                        vm.opstapObjects.push(opstap);
                    }
                },
                error: function (error) {
                    console.error('Failed to load locations', error);

                    vm.postcodeErrors.push('Failed to load locations');
                    vm.postcodeLoading = false;

                    $scope.$apply();
                },
                complete: function () {
                    console.log('Get opstapplaatsen csv done!', vm.opstapObjects);

                    if (!vm.opstapObjects || !vm.opstapObjects.length) {
                        console.error('No nearby opstap found');

                        vm.postcodeErrors.push('Could not find nearby location(s)');
                        vm.postcodeLoading = false;

                        $scope.$apply();

                        return;
                    }

                    const sortedOpstap = _.sortBy(vm.opstapObjects, 'distance');
                    // console.log('sortedOpstap', sortedOpstap);

                    const top3SortedOpstap = _.slice(sortedOpstap, 0, 5);
                    // console.log('top3SortedOpstap', top3SortedOpstap);

                    const filteredTickets = {};
                    _.each(vm.tickets, (tickets, eventId) => {
                        filteredTickets[eventId] = [];
                        _.forEach(top3SortedOpstap, opstap => {
                            _.forEach(tickets, ticket => {
                                if (ticket.description && ticket.description.replace(/[^a-zA-Z0-9]*/, '').toLowerCase() === opstap.raw_postcode) {
                                    filteredTickets[eventId].push(ticket);
                                }
                            });
                        });
                    });
                    // console.log('filteredTickets', filteredTickets);

                    vm.postcodeTickets = filteredTickets;
                    vm.postcodeLoading = false;

                    $scope.$apply();
                }
            });
        }

        vm.minlat = null;
        vm.maxlat = null;
        vm.minlon = null;
        vm.maxlon = null;

        function submitPostal() {
            console.log('SEARCHING FOR: ' + vm.postcode);

            if(typeof vm.postcode === 'undefined' || vm.postcode === ''){
                return;
            }

            const postcodeSearch = vm.postcode.replace(/[^0-9]+/g, '').trim();

            vm.postcodeObject = null;
            vm.opstapObjects = [];
            vm.postcodeTickets = {};
            vm.postcodeErrors = [];
            vm.postcodeLoading = true;

            let valid = validatePostal();
            if(!valid){
                vm.valid = false;
                vm.postcodeErrors.push('Geen geldige postcode');
                vm.postcodeLoading = false;
                return;
            }
            vm.valid = true;

            if (postcodeSearch !== '') {
                const zoekradius = 4; //15km
                const zoekradiuslat = zoekradius / 750;
                const zoekradiuslon = zoekradius / 50; //85km in Europe

                Papa.parse('csv/postcodes.csv', {
                    download: true,
                    header: true,
                    skipEmptyLines: true,
                    step: function(row) {
                        const postcode = row.data;

                        if ((row.errors && row.errors.length) || !postcode || !postcode.postcode || !postcode.latitude || !postcode.longitude) {
                            console.error('Postcodes data invalid, error found or does not contain "postcode", "latitude" and "longitude" field', postcode);
                        } else if (postcode.postcode === postcodeSearch && postcode.latitude && postcode.longitude) {
                            vm.postcodeObject = postcode;
                        }
                    },
                    error: function(error) {
                        console.error('Failed to load postcodes', error);

                        vm.postcodeErrors.push('Failed to load postcodes');
                        vm.postcodeLoading = false;

                        $scope.$apply();
                    },
                    complete: function() {
                        console.log('Get postcodes csv done!', vm.postcodeObject);

                        if (_.isNil(vm.postcodeObject)) {
                            console.error('Unable to parse location', postcodeSearch);

                            vm.postcodeErrors.push('Could not find postcode');
                            vm.postcodeLoading = false;

                            $scope.$apply();

                            return;
                        }

                        vm.minlat = _.get(vm.postcodeObject, 'latitude') * (1 - zoekradiuslat);
                        vm.maxlat = _.get(vm.postcodeObject, 'latitude') * (zoekradiuslat + 1);
                        vm.minlon = _.get(vm.postcodeObject, 'longitude') * (1 - zoekradiuslon);
                        vm.maxlon = _.get(vm.postcodeObject, 'longitude') * (zoekradiuslon + 1);

                        vm.allPostalsLoaded = true;

                        vm.findClosestTickets();



                    }
                });
            }
        }
    }).name;
