import router from '@uirouter/angularjs';
import bootstrap from 'angular-ui-bootstrap';
import storage from 'angular-storage';
import translate from 'angular-translate';
import ngCookies from 'angular-cookies';
import store from 'angular-storage';
import Sanitize from 'angular-sanitize';
import angulartics from 'angulartics';
import translateUrl from 'angular-translate/dist/angular-translate-loader-url/angular-translate-loader-url.js';
import Common from '@eventix/js-common'
import templateUrl from './app.html';
import styling from './app.less';
import Shop from './shop/shop.js';
import BreakFrame from './breakFrame/breakFrame.js';
import Order from './order/order.js';

angular.module('eventix', [
    router, ngCookies, store, bootstrap, storage, translate, translateUrl,
    Common, Shop, Sanitize, Order, angulartics, BreakFrame
])
    .config(function($locationProvider, $urlRouterProvider, APIPathInterceptorProvider,
        $stateProvider, $httpProvider, OAuthHeadersProvider, $translateProvider,
        GoogleTagManagerProvider, LocalesProvider, FBProvider, $qProvider) {
        /* Root state */
        var breakingFrame;
        $stateProvider.state('eventix', {
            url: '?_ga&coupon&preview&ignoreFrameRules',
            abstract: true,
            templateUrl: templateUrl,
            resolve: /*@ngInject*/ {
                frameInfo: function($state, $q, $transition$) {
                    var params = $transition$.params();
                    var info = {};
                    try {
                        info.width = window.innerWidth;
                        info.parentWidth = top.window.innerWidth;
                        info.isFramed = self !== top;
                        info.frameLocation = encodeURIComponent(window.top.location.toString());
                    } catch (err) {
                        info.width = 0;
                        info.parentWidth = 0;
                        info.isFramed = true;
                        info.frameLocation = '';
                    }
                    if(params.ignoreFrameRules || info.parentWidth - info.width <= 10)
                        info.isFramed = false;
                    return info;
                }
            },
            controller: 'AbstractShopController',
            controllerAs: 'vm'
        });
        $locationProvider.html5Mode(NODE_ENV !== 'testing');
        $urlRouterProvider.otherwise('/404');

        /* $http OAuth interceptor */
        OAuthHeadersProvider.setGrantType('client_credentials');
        APIPathInterceptorProvider.setPrefix(SHOP_API_ROOT);
        $httpProvider.interceptors.push('SafariCacheBuster');
        $httpProvider.interceptors.push('OAuthHeaders');
        $httpProvider.interceptors.push('APIPathInterceptor');

        $translateProvider.useLoader('languageLoader', {group: 'shop'})
            .useStorage('Locales')
            .useInterpolation('LaravelTranslator')
            .registerAvailableLanguageKeys(LocalesProvider.available, LocalesProvider.mapping)
            .uniformLanguageTag('java')
            .determinePreferredLanguage()
            .fallbackLanguage('en_GB');

        GoogleTagManagerProvider.addContainer(EVENTIX_GTM);
        FBProvider.setAppId(FB_APP_ID_SHOP);
        $qProvider.errorOnUnhandledRejections(NODE_ENV === 'development');
    })
    .run(function($rootScope, $state, Auth, $window, $translate, $location, Locales, Rollbar, $log, $analytics, store, VisitorData) {
        let queryParams = $location.search();
        if (queryParams.language && _.find(Locales, { code: queryParams.language }))
            $translate.use(queryParams.language);
        $rootScope.$on('unauthorized', function() {
            Auth.logout('client_credentials');
            $state.reload();
        });

        $rootScope.branding = {
            logo: SHOP_BRAND_LOGO,
            logoShop: SHOP_BRAND_LOGO_SHOP,
            name: SHOP_BRAND_NAME,
            url: SHOP_BRAND_URL,
            email: SHOP_SUPPORT_EMAIL,
            poweredBy: (SHOP_POWERED_BY).toString() === 'true',
            faqUrl: SHOP_FAQ_URL
        };

        $analytics.setUsername(VisitorData.visitorId);
    })
    .controller('AbstractShopController', function(VisitorData, $state, $scope) {
        const vm = this;

        vm.stateParams = $state.params;
        $scope.$watch('vm.stateParams', setLink, true);

        function setLink(stateParams) {
            if(!stateParams.shopId)
                return;
            let hasCode = _.get(VisitorData, 'queryParams.shop_code', false);
            let shopLink = '';
            if (hasCode)
                shopLink = TRACKER_ROOT + VisitorData.queryParams.shop_code;
            else {
                shopLink = $state.href('eventix.shop.tickets', {
                    shopId: stateParams.shopId
                }, { absolute: true });
            }
            shopLink += ((shopLink.indexOf('?') === -1) ? '?u=' : '&u=') + VisitorData.visitorId;
            vm.shopLink = $scope.shopLink = shopLink;
            vm.onMobile = _.get(VisitorData, 'userAgent.device.type') === 'mobile';
        }
    });

// Bootstrap the app ONLY after we have a token
angular.element(document).ready(function() {
    if (/^\/order/.test(window.location.pathname))
        return angular.bootstrap('.page-container', ['eventix']);
    var $injector = angular.injector(['ng', 'eventix.common.services.auth']);
    var el = document.getElementById('ng-app');
    angular.bootstrap(el, ['eventix']);
    angular.element(el).attr('ng-app', 'eventix');
});

